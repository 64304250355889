<template>
  <div>
    <div class="head">
      每个知识点的平均得分率变化情况
    </div>
    <div class="date_warp">
      <a-range-picker v-model:value="date"
                      type="daterange"
                      class="date"
                      value-format="YYYY-MM-DD"
                      separator="至"
                      @change="parmarsChange"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期">
      </a-range-picker>
      <span style="margin-right:12rem">
        范围
      </span>

      <a-select v-model:value="value"
                class="select"
                @change="parmarsChange"
                placeholder="范围"
                :options='options.map((item)=>({label:item.label,value:item.value}))'>
      </a-select>
    </div>

    <chart :series='series'
           :labels='labels'
           ref='chartRef' />
  </div>
</template>

<script setup>

import { getCharts } from '@/api/knowledgeLine.js'
import { defineEmits, ref, defineExpose, onMounted, nextTick } from 'vue'
import * as echarts from 'echarts'
import { rangeOptions } from './property.js'
import chart from './chart.vue'
let value = ref(7)
let value1 = ref('')
let series = ref([])
let labels = ref([])
let options = rangeOptions

let date = ref([])
// let colors = ['#FA7676', '#FAC376', '#76BBFA', '#7BEEC5', '#9176FA']
const emit = defineEmits(['parmarsChange'])
const parmarsChange = () => {
  labels.value = []
  series.value = []
  emit('parmarsChange')
}

let chartRef = ref()
const removeData = () => {
  //date.value = ''
  series.value = []
  labels.value = []
}
const remove = () => {
  removeData()
  chartRef.value.clear()
}
const del = (index) => {
  series.value.splice(index, 1)
  if (series.value.length == 0) {
    labels.value = []
  } else {
    series.value.splice(index, 1)
  }
  chartRef.value.clear()
}
const getData = async (id, index) => {
  // console.log(index)
  if (!id) {
    series.value = []
    labels.value = []
    chartRef.value.clear()
  }
  //获取数据
  const { data } = await getCharts({
    knowledge_id: id,
    start_day: date.value[0] || '',
    end_day: date.value[1] || '',
    day_num: value.value
  })
  //匹配echarts数据格式
  let data1 = {
    data: [],
    type: 'line',
    name: data.list[0].knowledge_desc,
    smooth: true
  }
  data1.data = data.list.map(item => {
    return item.score
  })
  if (!labels.value.length) {
    labels.value = data.list.map(item => {
      return item.date_str
    })
  }
  // series.value[0] = data1
  series.value[index] = data1
  // series.value.push(data1)

}
defineExpose({ getData, del, remove, removeData })

onMounted(() => {
  nextTick(() => {
    chartRef.value.drawLine()
  })
})

</script>

<style lang="scss" scoped>
.head {
  display: flex;
  position: relative;
  justify-content: center;
  font-size: 30rem;
  font-weight: bold;
  color: #000000;
  margin-top: 20rem;
}
.date_warp {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 28rem;
  .date {
    width: 420rem;
    right: 52rem;
  }
  span {
    font-size: 22rem;
    font-weight: 500;
    color: #666666;
  }
  .select {
    width: 132rem;
  }
}

:deep .ant-picker-input > input {
  width: 100%;
  min-width: 0;
  padding: 2rem 1rem;
  font-size: 20rem;
}
:deep .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 40rem;
  line-height: 40rem;
}

:deep .ant-select {
  height: 40rem;
  font-size: 20rem;
}

:deep .ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 40rem;
}
</style>