<template>
  <div class="my_test_paper">
    <div class="back">
      <img src="@/static/back.png"
           @click="back"
           alt="">
      <div>
        <dropDown :list='subjectList'
                  :index='subjectIndex'
                  attribute_name='subject_name'
                  background='#1372E9'
                  @changeMenu='subjectChange' />
      </div>
    </div>
    <div class="warp">
      <div class="left_knowledge">
        <div :class="{'item':true,'active':checkArr.indexOf(item.knowledge_id) != -1}"
             @click="checkItem(item.knowledge_id)"
             v-for="(item,index) in knowledgeList"
             :key="index">
          {{item.knowledge_desc}}
        </div>
      </div>
      <div class="right_charts">
        <div class="head">
          <div :class="{'c1':type == 'DflRef'}"
               @click="check('DflRef')">知识点得分率曲线</div>
          <div :class="{'c2':type == 'LeijiRef'}"
               @click="check('LeijiRef')">知识点累积得分率曲线</div>
        </div>
        <div class="table_content">
          <Dfl ref="DflRef"
               v-show="type == 'DflRef'"
               @parmarsChange='parmarsChange'
               :colors='colors' />
          <Leiji ref="LeijiRef"
                 @parmarsChange='parmarsChange'
                 v-show="type!= 'DflRef'" />
          <div class="labels">
            <div class="item"
                 v-for="(item,index) in checkArr"
                 :key="item">
              <span class="yuan"
                    :style="{'background':colors[index]}">
              </span>
              {{knowledgeList.find(ele=>ele.knowledge_id == item).knowledge_desc}}
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
import { DownOutlined } from '@ant-design/icons-vue';
import Dfl from './components/dfl.vue'
import Leiji from './components/leiji.vue'
import { useRouter } from 'vue-router'
import { ref, watch, toRef, nextTick } from 'vue'
import { useStore } from 'vuex'
import { getUserSubject } from '@/api/subject.js'
import { getKnowledgeList } from '@/api/knowledgeLine.js'
import dropDown from '@/views/components/dropdown/index.vue'

let router = useRouter()
const store = useStore()
let subjectList = ref([])
let subjectIndex = ref(0)
let knowledgeList = ref([])
let type = ref('DflRef')
let checkArr = ref([])
let colors = ['#FA7676', '#FAC376', '#76BBFA', '#7BEEC5', '#9176FA']
// 获取科目
subjectList.value = store.state.subject.subjectList
const getSubject = async () => {
  if (!subjectList.value.length) {
    const { data } = await getUserSubject()
    subjectList.value = data.list
    store.commit('subject/setSubjectList', data.list)
  }
  // console.log(subjectList.value)
  getKnowledge()
}
const getKnowledge = async () => {
  const { data } = await getKnowledgeList({
    subject_id: subjectList.value[subjectIndex.value].subject_id
  })
  knowledgeList.value = data.list
}
let DflRef = ref()
let LeijiRef = ref()
const checkItem = (id) => {
  if (checkArr.value.indexOf(id) != -1) {
    var idx = checkArr.value.indexOf(id)
    checkArr.value.splice(idx, 1)
    if (type.value == 'DflRef') {
      DflRef.value.del(idx)
    } else {
      LeijiRef.value.del(idx)
    }
    // this.$refs[this.type].del(idx)

  } else {
    if (checkArr.value.length == 5) return
    checkArr.value.push(id)
    nextTick(() => {
      if (type.value == 'DflRef') {
        DflRef.value.getData(id, checkArr.value.length)
        // DflRef.value.colors = colors
      } else {
        LeijiRef.value.getData(id, checkArr.value.length)
      }
      // this.$refs[this.type].getData(index)
    })
  }
}

// 子组件查询条件变化的时候触发这里 循环调接口
const parmarsChange = () => {
  nextTick(() => {
    checkArr.value.forEach((item, index) => {
      if (type.value == 'DflRef') {
        DflRef.value.getData(item, index)
      } else {
        LeijiRef.value.getData(item, index)
      }

    });
  })
}
const back = () => {
  router.go(-1)
}
getSubject()

const check = (str) => {
  if (str == type.value) return
  type.value = str
  nextTick(() => {
    if (type.value == 'DflRef') {
      DflRef.value.removeData()
    } else {
      console.log(LeijiRef)
      LeijiRef.value.removeData()
    }
    // this.$refs[this.type].removeData()
    checkArr.value.forEach((item, index) => {
      // console.log(index)
      // this.$refs[this.type].getData(item)
      if (type.value == 'DflRef') {
        DflRef.value.getData(item, index)
      } else {
        LeijiRef.value.getData(item, index)
      }
    });
  })
}
const subjectChange = (index) => {
  checkArr.value = []
  subjectIndex.value = index
}
watch(subjectIndex, (newValue, oldValue) => {
  checkArr.value = []
  getKnowledge()
  if (type.value == 'DflRef') {
    DflRef.value.remove()
  } else {
    if (LeijiRef.value) {
      LeijiRef.value.remove()
    }

  }
})


</script>

<style lang="scss" scoped>
.my_test_paper {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  background-image: url("../../static/allBackg/bg-知识点曲线@2x.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.back {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30rem 30rem 0 50rem;
  cursor: pointer;
  img {
    border-radius: 50%;
    box-shadow: 0px 1px 19px 0px #2f3ad7;
    width: 60rem;
    height: 60rem;
  }
  div {
    margin-right: 10rem;
  }
}

.warp {
  margin-left: 60rem;
  margin-top: 45rem;
  display: flex;
  .left_knowledge {
    width: 400rem;
    height: calc(100vh - 240rem);
    border: 10rem solid #fedd94;
    border-radius: 30rem;
    background-color: #fac170;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    overflow-y: scroll;
    .item {
      cursor: pointer;
      margin: 10rem;
      font-size: 28rem;
      font-weight: bold;
      color: #b16700;
      width: 360rem;
      height: 80rem;
      line-height: 80rem;
      text-indent: 30rem;
      border-radius: 10rem;
      white-space: nowrap;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .right_charts {
    width: 1200rem;
    height: 740rem;
    margin-left: 50rem;
    background-image: url("../../static/knowledge/bg-背景.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .head {
      font-size: 30rem;
      width: 61%;
      font-weight: 800;
      color: #b16700;
      display: flex;
      height: 95rem;
      div {
        cursor: pointer;
        &:nth-child(1) {
          width: 346rem;
        }
        &:nth-child(2) {
          flex: 1;
        }
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.active {
  background: #ffebc0;
  border-radius: 5px;
}
.c1 {
  background-image: url("../../static/knowledge/bg-白色块@2x.png");
  background-size: 95.8% 87%;
  background-repeat: no-repeat;
  background-position: bottom right;
}
.c2 {
  background-image: url("../../static/knowledge/bg-白色块@2x.png");
  background-size: 95.8% 87%;
  background-repeat: no-repeat;
  background-position: bottom right;
}
.table_content {
  width: 95%;
  margin: 0 auto;
  .labels {
    display: flex;
    margin-left: 40px;
    flex-wrap: wrap;
    .item {
      display: flex;
      align-items: center;
      margin-right: 30rem;
      font-size: 22rem;
      font-weight: 400;
      color: #333333;
      span {
        width: 20rem;
        height: 20rem;
        border-radius: 50%;
        margin-right: 8rem;
      }
    }
  }
}
:deep .ant-dropdown-link .ant-dropdown-menu-title-content {
  font-size: 24rem;
  padding: 0 20rem;
  line-height: 1.5em;
}
:deep .ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  font-size: 24rem;
  padding: 0 20rem;
  line-height: 1.5em;
}
.ant-dropdown-link {
  img {
    width: 26rem;
    height: 26rem;
    margin-left: 14rem;
  }
}
</style>